import React from "react"
import get from 'lodash/get'
import { graphql, Link } from "gatsby"
import { Grid, Typography } from "@material-ui/core"

import Layout from "../components/layout"
import PostPreview from "../components/BlogPosts/postPreview"
import SEO from "../components/seo"
// meta={ [`Zain`, `Khalid`, `Zain Khalid`, `Software`, `Software Developer`, `Developer`,
//           `Full Stack`, `Full`, `Full Stack Developer`, `Android`, `Android Developer`, `Personal Site`, `Personal`, `Site`] }

const renderLatestBlogPostsList = (blogPosts) => {
  return blogPosts.map(post => <PostPreview key={post.id} post={post} />)
}

class IndexPage extends React.Component {
  
  render() {
    const blogPosts = get(this, 'props.data.allCosmicjsBlogPosts.nodes')
    
    return (
      <div>
        <SEO lang="en" title="Home" />
        <Layout>
          <Grid container direction="column">
            <Grid item xs={12} style={{ marginBottom: `2.5rem`, marginTop: `1.0rem`, textAlign: `center` }}><Typography variant="h5">Latest Posts</Typography></Grid>
            <Grid container direction="column" justify="center">{renderLatestBlogPostsList(blogPosts)}</Grid>
            <Grid item xs={12} style={{ textAlign: `right` }}>
              <Link to="/blog">
                <Typography variant="body2" style={{ color: `purple`, fontWeight: `600`, boxShadow: `none`, textDecoration: `none`, '&:hover': { textDecoration: `underline`} }}>All Posts ></Typography>
              </Link>
            </Grid>
          </Grid>
        </Layout>
      </div>
    )
  }
}

export default IndexPage

export const dataQuery = graphql`
query LatestBlogQuery {
  allCosmicjsBlogPosts(sort: {fields: [created], order: DESC}, limit: 5) {
    nodes {
      id
      metadata {
        post_content
        post_date
        post_spoiler
        post_title
      }
      slug
    }
  }
}
`